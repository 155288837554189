/* ======================  SCRIPTS ====================== */

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var visitorHoverSubmenu = false;

    $('document').ready(setup);
    $(window).resize(gestionResizeMobtab);

    function setup() {
        /*initTarteAuCitron();*/
        verifIfIsRegularLinesPage();
        marginHangsHeaderIE();
        adaptCardWithReadMore();
        scriptForMyTransdevLogin();
        showModalAfterSendForm();
        verifIfFormSended();
        manseryGridConstruct();
        scrollToDepContent();
        initHomeRegionSlider();
        $('.navigation .btn-open, .footer .btn-open, .navsecondary .btn-open').click(showHideWebsitesMenu);
        $('.burger').click(showHideMenuResponsive);
        $('.sub-nav .titlesite').click(showHideMenuResponsiveDep);
        if($('.homepage').length != 0) {
            $('.homepage-map .department ul li a').mouseover(hoverDepLinkMap).mouseleave(hoverDepLinkMap);
            hoverMap();
            minHeightIEPortraitHuman();
        }
        $(window).scroll(showHideTopPage);
        $('.btn-topage').click(goTopPage);
        $('.toggleBloc-btn').click(showFiltersForNews);
        $('body').on('click', '.toggleBloc [type="submit"]', closeFilters);
        $('.social-share').click(copyLink);
        $('.openModal').click(openModal);
        $('.webform-submission-form .webform-button--submit').click(testerFormulaire);
        $('.webform-submission-form input:not(.webform-button--submit, .hasDatepicker, [type="file"]), .webform-submission-form textarea').focusout(verificationALaVolee);
        $('.webform-submission-form input[type="checkbox"]').on('change', verificationALaVoleeCheckbox);
        $('#edit-resa-departure-ligne07, #edit-resa-departure-ligne12, #edit-resa-departure-ligne12-velo, #edit-resa-departure-ligne13, #edit-resa-departure-ligne13-velo').change(verifPreResaSelect);
        $('.webform-submission-form select').change(verificationALaVolee);
        if($('.slider').length > 0){
            $('.slider').slick({
                dots: true,
                infinite: true,
                autoplay: true,
                speed: 1200,
                fade: true,
                cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
                slidesToShow: 1,
                adaptiveHeight: true
            });
        }
        $('.hasChildren > span').click(showSubmenu);
        initResponsiveSlider();
        $(window).on('resize', initResponsiveSlider);
        $('.folder-title').click(showHideDocuments);
        $('body').on('click', 'div[role="contentinfo"]', function(){
            $(this).detach();
        });
        $('[data-social]').socialButtons();
        if($('#leafletmap').length != 0) initMap();
        if($('.blockForm').length != 0) addSelectListDates();
        if($('.blockForm .webform-confirmation').length != 0) $('.blockForm').slideDown(300, function() { $('html, body').animate({ scrollTop: $('.blockForm').offset().top - 100  }, 600); });
        $('.btn-show-reservation').click(showReservationForm);
    }

    // Fonction qui permet d'initialiser le bandeau des cookies avec tarteaucitron
    /*function initTarteAuCitron() {
        tarteaucitron.init({
            "cookieName": "transdev",
            "orientation": "bottom",
            "showAlertSmall": false,
            "highPrivacy": false,
            "cookieslist": true,
            "removeCredit": true,
            "moreInfoLink": true,
            "useExternalCss": true,
        });

        var domain = window.location.hostname;

        if(domain.indexOf('transdev.prod.hebergement-gm.fr') != -1 || domain.indexOf('transdev-bretagne-pdl.com')!= -1 || domain.indexOf('transdev-bplcvl.com')!= -1) {
            tarteaucitron.user.gtagUa = 'UA-117081252-1';
        }else if(domain.indexOf('transdev-bretagne.com')!= -1 || domain.indexOf('cotesdarmor.transdev-bretagne.com')!= -1 || domain.indexOf('finistere.transdev-bretagne.com')!= -1 || domain.indexOf('morbihan.transdev-bretagne.com')!= -1 || domain.indexOf('illeetvilaine.transdev-bretagne.com')!= -1) {
            tarteaucitron.user.gtagUa = 'UA-117081252-2';
        }else if(domain.indexOf('transdev-paysdelaloire.com')!= -1 || domain.indexOf('loireatlantique.transdev-paysdelaloire.com')!= -1 || domain.indexOf('mayenne.transdev-paysdelaloire.com')!= -1 || domain.indexOf('sarthe.transdev-paysdelaloire.com')!= -1 || domain.indexOf('maineetloire.transdev-paysdelaloire.com')!= -1) {
            tarteaucitron.user.gtagUa = 'UA-117081252-3';
        }

        tarteaucitron.user.gtagMore = function () {  };
        (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
        (tarteaucitron.job = tarteaucitron.job || []).push('youtube');
    }*/

    function gestionResizeMobtab() {
        manseryGridConstruct();
        if($('.homepage').length != 0) minHeightIEPortraitHuman();
        marginHangsHeaderIE ();
    }

    function goTopPage() {
        $('html, body').animate({scrollTop: 0}, 1500);
    }

    function showHideTopPage() {
        if($(this).scrollTop() >= 1000){
            $('.btn-topage').addClass('show');
        }else {
            $('.btn-topage').removeClass('show');
        }
    }

    function initHomeRegionSlider() {
      if($('.slider-regions').length > 0){
        $('.slider-regions').slick({
          dots: true,
          infinite: true,
          autoplay: false,
          speed: 700,
          fade: false,
          slidesToShow: 1,
          adaptiveHeight: false,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                adaptiveHeight: true,
              }
            }
          ]
        });
      }
    }

    // Fonctions qui permettent d'animer la carte des départements en SVG
    function hoverMap() {
        var svg = document.getElementById("svgmap");
        svg.addEventListener("load",function(){
            var svgDoc = svg.contentDocument;
            var elem = svgDoc.getElementsByClassName("st0");
            for(var i=0; i<elem.length; i++) {
                elem[i].addEventListener('mouseover', hoverSvgElem, true);
                elem[i].addEventListener('mouseout', hoverSvgElem, true);
                elem[i].addEventListener('click', openDepWebsite, true);
            }
        }, false);
    }

    function hoverSvgElem(e) {
        var depId = $(e.target).attr('id');
        if($(e.target).attr('class').indexOf('currenthov') == -1) {
            $(e.target).addClass('currenthov');
            $('.department #' + depId).addClass('active');
        }else {
            $(e.target).removeClass('currenthov');
            $('.department #' + depId).removeClass('active');
        }
    }

    function openDepWebsite(e) {
        var depId = $(e.target).attr('id');
        var depUrl = $('.department #' + depId).children('a').attr('href');
        window.open(depUrl, '_blank');
    }

    function hoverDepLinkMap () {
        var depId = $(this).parent().attr('id');
        var svg = document.getElementById("svgmap");
        var svgDoc = svg.contentDocument;
        var elem = svgDoc.getElementById(depId);

        if($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
            elem.removeAttribute('style');
        }else {
            $(this).parent().addClass('active');
            elem.style.fill = '#1f2f45';
            elem.style.strokeWidth = '4';
            elem.style.stroke = '#1f2f45';
        }
    }

    // Fonction qui permet d'ajuster l'alignement des éléments du Header sur IE
    function marginHangsHeaderIE () {
        if (msie > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            $('.header-hangs').removeAttr('style');
            var headerHeight = $('.header').innerHeight();
            var hangsHeight = $('.header-hangs').innerHeight();

            if((headerHeight > hangsHeight)) {
                if($(window).width() <= 1200) {
                    var marginHeight = ((headerHeight - $('.breadcrump').innerHeight()) - hangsHeight) / 2;
                    $('.header-hangs').css('margin-top', marginHeight+'px');
                    $('.header-hangs').css('margin-bottom', marginHeight+'px');
                }else {
                    var marginHeight = (headerHeight - hangsHeight) / 2;
                    $('.header-hangs').css('margin-top', marginHeight+'px');
                    $('.header-hangs').css('margin-bottom', marginHeight+'px');
                }
            }
        }
    }

    // Fonction qui permet d'afficher/cacher le menu "Nos sites web"
    function showHideWebsitesMenu (e) {
        e.preventDefault();
        if($(this).hasClass('open')) {
            $('.fadebg').fadeOut(600);
            $('html, body').removeAttr('style');
            $('.btn-open').removeClass('open');
            $('.transdevsites').removeClass('active');
        }else {
            $('html, body').css('overflow', 'hidden');
            $('.btn-open').addClass('open');
            $('.fadebg').fadeIn(600);
            $('.transdevsites').addClass('active');
            $('.transdevsites-back').click(function() {
                if($('.navigation-menu').hasClass('open') == false) $('html, body').removeAttr('style');
                $('.btn-open').removeClass('open');
                $('.fadebg').fadeOut(600);
                $('.transdevsites').removeClass('active');
            });
        }
    }

    // Fonction qui permet d'afficher/cacher le menu responsive du site
    function showHideMenuResponsive () {
        if($(this).hasClass('active')) {
            $('html, body').removeAttr('style');
            $(this).removeClass('active');
          $('.mobileMenu').removeClass('open');
          $('.mobileMenu + div, .mobileMenu + main').removeClass('hide');
        }else {
            $('html, body').css('overflow', 'hidden');
            $(this).addClass('active');
            $('.mobileMenu + div, .mobileMenu + main').addClass('hide');
            $('.mobileMenu').addClass('open');
        }
    }

    // Fonction qui permet d'afficher/cacher le sous-menu responsive des pages Département
    function showHideMenuResponsiveDep () {
      if($(window).width() < 1000) {
        if($(this).parent().hasClass('open')) {
          $(this).parent().removeClass('open');
        }else {
          $(this).parent().addClass('open');
        }
      }
    }

    // Fonction qui permet d'afficher/cacher les sous menu Départements
    function showSubmenu() {
        if($(this).parent().hasClass('expanded')) {
            $(this).parent().children('ul').slideUp(600);
            $(this).parent().removeClass('expanded');
        }else {
            $(this).parent().children('ul').slideDown(600);
            $(this).parent().addClass('expanded');
        }
    }

    function hideSubmenu() {
        $(this).slideUp(600);
        $(this).parent().removeClass('expanded');
    }

    function hideSubmenuFromNav() {
        $(this).children('.navsecondary-menu').children('.hasChildren').children('ul').slideUp(600);
        $(this).children('.navsecondary-menu').children('.hasChildren').removeClass('expanded');
    }

    function scrollToDepContent() {
      if($(window).width() >= 1200) {
        $('.submenu li:not(:first-child)').each(function() {
          if($(this).children('a').hasClass('is-active')) $('html, body').animate({scrollTop: ($('.breadcrump').offset().top - 150)}, 800);
        });
      }
    }

    // Fonction qui permet de construire la grille d'actualités façon Masonry
    function manseryGridConstruct() {
        if($('.news').length != 0) {
            var $grid = $('.news-grid');
            $grid.masonry({
                // options
                gutter: 0,
                horizontalOrder: true,
                itemSelector: '.news-card'
            });

            $grid.imagesLoaded().progress( function() {
                $grid.masonry('layout');
            });
        }
    }

    // Adapter la taille du portrait du bloc "Une aventure humaine" de la home sur IE
    function minHeightIEPortraitHuman() {
        if (msie > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) $('.portrait img').css('height', $('.portrait').outerHeight() + 'px');
    }

    // Gestion des blocs pour afficher / cacher les filtres des news
    function showFiltersForNews() {
        if($(this).hasClass('open') == true) {
            $(this).removeClass('open');
            $(this).next().slideUp(400);
        }else {
            if($(window).width() < 760) {
                $('.open').next().slideUp(400);
                $('.open').removeClass('open');
            }
            $(this).addClass('open');
            $(this).next().slideDown(400, function() {
                if($(this).children('ul').height() > 280) $(this).children('ul').mCustomScrollbar();
            });
        }
    }

    function closeFilters(){
        var $toggleBtn = $(this).closest('.toggleBloc').find('.toggleBloc-btn');
        $toggleBtn.removeClass('open');
        $toggleBtn.next().slideUp(400);
    }

    // Fonction qui permet de copier l'url d'un article ou d'une offre d'emploi
    function copyLink() {
        $('body').append('<input type="url" class="urlToCopy sr-only" value="' + location.href + '"/>');
        var urlVal = $('.urlToCopy').select();
        document.execCommand('copy');
        $(this).children('.tooltip').addClass('copied');
        setTimeout(function() { $('.copied').removeClass('copied'); }, 1600);
    }

    // Fonction qui permet d'ouvrir la modale pour postuler à une offre d'emploi
    function openModal() {
        $('html, body').css('overflow', 'hidden');
        $('body').addClass('blured');
        $('.modal').fadeIn(400, function() { $('.modal-content').addClass('open'); });
        $('.modal .webform-submission-form .webform-button--submit').click(testerFormulaire);

        $('.modal-close').click(function() {
            $('.modal-content').removeClass('open');
            setTimeout(function() { $('.modal').fadeOut(500); $('body').removeClass('blured'); $('html, body').removeAttr('style'); }, 300);
        });
    }

    // Fonction qui permet de vérifier la saisie d'un formulaire
    function testerFormulaire(e) {
        // vérification des saisies utilisateurs
        var nbChampVide = 0;
        $('.error-msg').remove();
        $('.fieldError').removeClass('fieldError');
        $('.valid-msg').removeClass('valid');
        $('.valid').removeClass('valid');

        // On vérifie la saisie des champs obligatoire
        $('.webform-submission-form input').each(function() {
            if($(this).attr('required') == 'required') {
                var textError = $(this).attr('data-webform-required-error');

                if($(this).val() == "" || $(this).val() == " ") { // On vérifie que le champ n'est pas vide
                    nbChampVide++;
                    $(this).addClass('fieldError');
                    $(this).parent().append( "<div class='error-msg'>" + textError + "</div>" );
                    $('.error-msg').fadeIn();
                }else { // S'il n'est pas vide on vérifie s'il y a une verification supplémentaire sur le champ
                    var name = $(this).attr('name');
                    if(name == 'email') {
                        var regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
                        if (regex.test($(this).val()) == false) {
                            nbChampVide++;
                            $(this).addClass('fieldError');
                            $(this).parent().append("<div class='error-msg'>" + textError + "</div>");
                            $('.error-msg').fadeIn();
                        }else {
                            $(this).addClass('valid');
                        }
                    }else if(name == 'telephone') {
                        var regexPhone = /^((\+|00)33\s?|0)[1-9](\s?\d{2}){4}$/;
                        if (regexPhone.test($(this).val()) == false) {
                            nbChampVide++;
                            $(this).addClass('fieldError');
                            $(this).parent().append("<div class='error-msg'>" + textError + "</div>");
                            $('.error-msg').fadeIn();
                        }else {
                            $(this).addClass('valid');
                        }
                    }else {
                        $(this).addClass('valid');
                    }
                }
            }
        });

        $('.webform-submission-form textarea').each(function() {
            if($(this).attr('required') == 'required') {
                var textError = $(this).attr('data-webform-required-error');

                if($(this).val() == "" || $(this).val() == " ") { // On vérifie que le champ n'est pas vide
                    nbChampVide++;
                    $(this).addClass('fieldError');
                    $(this).parent().append( "<div class='error-msg'>" + textError + "</div>" );
                    $(this).parent().addClass('hasError');
                    $('.error-msg').fadeIn();
                }else { // S'il n'est pas vide on vérifie s'il y a une verification supplémentaire sur le champ
                    $(this).addClass('valid');
                }
            }
        });

        // On vérifie la saisie des champs select obligatoire
        $('.webform-submission-form select').each(function() {
            if ($(this).attr('required') == 'required') {
                var textError = $(this).attr('data-webform-required-error');
                if($(this).val() == "" || $(this).val() == " " || $(this).val() == null) { // On vérifie que le champ n'est pas vide
                    nbChampVide++;
                    $(this).parent().append( "<div class='error-msg'>" + textError + "</div>" );
                    $(this).addClass('fieldError');
                    $('.error-msg').fadeIn();
                }
            }
        });

        // On vérifie la saisie des cases à cocher
        $('.webform-submission-form input[type="checkbox"]').each(function() {
            if($(this).attr('required') == 'required') {
                var textError = $(this).attr('data-webform-required-error');

                if($(this).prop('checked') == false) { // On vérifie que le champ n'est pas vide
                    nbChampVide++;
                    $(this).parent().addClass('fieldError');
                    $(this).parent().children('label').after( "<div class='error-msg'>" + textError + "</div>" );
                    $('.error-msg').fadeIn();
                }
            }
        });

        if(nbChampVide !== 0) {
            e.preventDefault();
            var i = 0;
            var topErrorFirst;
            if($(".modal").length != 0) {
                $('.webform-submission-form .fieldError').each(function() {
                    if(i == 0) topErrorFirst = $(this).position().top;
                    if($(this).position().top < topErrorFirst) topErrorFirst = $(this).position().top;
                    i++;
                });
            }else {
                $('.webform-submission-form .fieldError').each(function() {
                    if(i == 0) topErrorFirst = $(this).offset().top;
                    if($(this).offset().top < topErrorFirst) topErrorFirst = $(this).offset().top;
                    i++;
                });
            }

            if($(".modal").length != 0) {
                $(".modal").animate({ scrollTop: topErrorFirst - 55 }, 600);
            }else {
                $('html, body').animate({ scrollTop: topErrorFirst - 80 }, 600);
            }
        }
    }

    // Vérification des données saisies à la volée
    function verificationALaVolee() {
        if($(this).attr('required') == 'required') {
            var textError = $(this).attr('data-webform-required-error');

            if($(this).val() == "" || $(this).val() == " " || $(this).val() == null) { // On vérifie que le champ n'est pas vide
                $(this).removeClass('valid');
                $(this).addClass('fieldError');
                if($(this).parent().children('.error-msg').length == 0) {
                    $(this).parent().append( "<div class='error-msg'>" + textError + "</div>" );
                    $(this).parent().children('.error-msg').fadeIn();
                }else {
                    $(this).parent().children('.error-msg').fadeOut();
                    $(this).parent().children('.error-msg').fadeIn();
                }
            }else { // S'il n'est pas vide on vérifie s'il y a une verification supplémentaire sur le champ
                var name = $(this).attr('name');
                if(name == 'email') {
                    var regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
                    if (regex.test($(this).val()) == false) {
                        $(this).removeClass('valid');
                        $(this).addClass('fieldError');
                        if($(this).parent().children('.error-msg').length == 0) {
                            $(this).parent().append( "<div class='error-msg'>" + textError + "</div>" );
                            $(this).parent().children('.error-msg').fadeIn();
                        }else {
                            $(this).parent().children('.error-msg').fadeOut();
                            $(this).parent().children('.error-msg').fadeIn();
                        }
                    }else {
                        $(this).addClass('valid');
                        $(this).removeClass('fieldError');
                        $(this).parent().children('.error-msg').remove();
                    }
                }else if(name == 'telephone') {
                    var regexPhone = /^((\+|00)33\s?|0)[1-9](\s?\d{2}){4}$/;
                    if (regexPhone.test($(this).val()) == false) {
                        $(this).removeClass('valid');
                        $(this).addClass('fieldError');
                        if($(this).parent().children('.error-msg').length == 0) {
                            $(this).parent().append( "<div class='error-msg'>" + textError + "</div>" );
                            $(this).parent().children('.error-msg').fadeIn();
                        }else {
                            $(this).parent().children('.error-msg').fadeOut();
                            $(this).parent().children('.error-msg').fadeIn();
                        }
                    }else {
                        $(this).addClass('valid');
                        $(this).removeClass('fieldError');
                        $(this).parent().children('.error-msg').remove();
                    }
                }else if($(this).hasClass('form-number')) {
                    if($(this).val() < 0) {
                        $(this).val(1);
                        $(this).addClass('valid');
                        $(this).removeClass('fieldError');
                        $(this).parent().children('.error-msg').remove();
                    }
                }else{
                    $(this).addClass('valid');
                    $(this).parent().children('.error-msg').remove();
                }
            }
        }

        $('.webform-submission-form .hasDatepicker').each(function() {
            if($(this).val() != '') {
                $(this).addClass('valid');
                $(this).parent().children('.error-msg').remove();
            }
        });
    }

    function verificationALaVoleeCheckbox() {
        if($(this).attr('required') == 'required') {
            if($(this).prop('checked') == false) {
                var textError = $(this).attr('data-webform-required-error');
                $(this).parent().children('label').after( "<div class='error-msg'>" + textError + "</div>" );
                $('.error-msg').fadeIn();
                $(this).parent().addClass('fieldError');
            }else {
                $(this).parent().children('.error-msg').remove();
            }
        }
    }

    // Affichage de la modale de confirmation lorsque l'on a envoyé un formulaire de candidature ou réservation PMR
    function verifIfFormSended() {
        setTimeout(function() {
            if(($('.page-node-type-job-offer').length != 0 || $('#page-carrieres').length != 0 || $('.regionalReservation').length != 0) && ($('.message').length != 0)) {
                $('html, body').css('overflow', 'hidden');
                var confirmationMessage = $('.webform-confirmation .message').html();
                $('.modal-content .webform-submission-form').remove();
                $('.modal-content').append('<div class="message">' + confirmationMessage + '</div>');
                openModal();
            }
        }, 500);
    }

    // Fonction qui permet d'empêcher de sélectionner le même lieu d'arrivée que de départ pour les pré-réservation PMR
    function verifPreResaSelect() {
        var startPlaceSelected = $(this).val();
        var arrivePlace = $(this).parent().next().children('select');
        arrivePlace.children('option').show();
        arrivePlace.children('option').prop("selected", false);
        arrivePlace.children('option[value="'+startPlaceSelected+'"]').hide();
        arrivePlace.val('');
    }

    // Fonction qui permet de charger la carte de la page contact
    function initMap() {
        if($('.agency').length != 0) {
            // On récupère la moyenne des longitudes et latitudes pour centrer la carte sur les agences affichées
            var latitudeMoy = 0;
            var longitueMoy = 0;

            $('.agency').each(function( index ) {
                var latitude = $(this).attr('data-latitude');
                var longitude = $(this).attr('data-longitude');

                latitudeMoy = latitudeMoy + parseFloat(latitude);
                longitueMoy = longitueMoy + parseFloat(longitude);
            });

            var latCenterMap = latitudeMoy / $('.agency').length;
            var longCenterMap = longitueMoy / $('.agency').length;

            var centerMap = { lat: parseFloat(latCenterMap), lng: parseFloat(longCenterMap)};

            if($(window).width() < 640) {
                var zoomMap = 6;
            }else {
                if($('#block-block-map-agencies').length != 0) {
                    var zoomMap = 7;
                }else {
                    var zoomMap = 8;
                }
            }

          var zoomLimits = {
            min: 3,
            max: 16
          };

            var map = L.map('leafletmap', {
              center: centerMap,
              zoom: zoomMap,
              minZoom: zoomLimits.min,
              maxZoom: zoomLimits.max,
              touchZoom: true,
              keyboard: false,
              attributionControl: false,
              scrollWheelZoom: false,
              trackResize: true,
              fadeAnimation: true,
              zoomAnimation: true,
              layerControl: false,
              minZoom: 3
            });

          L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', { attribution: '&copy; Transdev', attributionControl: false, minZoom: 3 }).addTo(map);

          $('.agency').each(function( index ) {
            var latitude = $(this).attr('data-latitude');
            var longitude = $(this).attr('data-longitude');

            var id = $(this).attr('data-history-node-id');
            var agencyName = $(this).children('.agency-name').children('h2').html();
            var html = '<div class="marker"><div class="markup"></div><div class="content"><p>Vous affichez actuellement<br/>les coordonnées de cette agence.</p><button class="btn btn-close-cluster"><span>Fermer</span><span class="icon">X</span></button></div></div>';

            var myIcon = L.divIcon({
              className: 'cluster-agency marker-' + id,
              iconSize: [24, 32],
              iconAnchor: [12, 20],
              html: html,
            });

            var marker = L.marker([latitude, longitude], {icon: myIcon}).on('click', showAgencyInfos).addTo(map);
          });
        }
    }

    function showAgencyInfos() {
      var clusterClass = this._icon.classList[2];
      var clusterID = clusterClass.split('marker-');
      $('.currentmarker').removeClass('currentmarker');
      this._icon.classList += ' currentmarker';

      $('.agency').each(function( index ) {
        $(this).removeClass('currentShow');
        if(parseInt(clusterID[1]) === parseInt($(this).attr('data-history-node-id'))) $(this).addClass('currentShow');
      });

      if($('.mapagencies-close').length != 0) {
        $('.mapagencies-close').addClass('show');
        $('.mapagencies-close').click(function() {
          $('.currentShow').removeClass('currentShow');
          $('.mapagencies-close').removeClass('show');
          $('.currentmarker').removeClass('currentmarker');
        });
      }

      if($('.map .h2-title').length != 0) $('html, body').animate({ scrollTop: $('.map .h2-title').offset().top - 150 }, 1200);

      $('.btn-close-cluster').click(function(event) {
          event.preventDefault();
          event.stopPropagation();
          $(this).parent().parent().parent().removeClass('currentmarker');
          $('.agency').removeClass('currentShow');
      });
    }

    // Fonction qui permet de transformer certains éléments en slider en responsive
    function initResponsiveSlider() {
        if($(window).width() < 1200) {

            if($('.responsiveSlider').not('.regionalJobs-partners').length > 0) {
                $('.responsiveSlider').not('.regionalJobs-partners').slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    speed: 800,
                    cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    responsive: [{
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });
            }

            if($('.regionalJobs-partners.responsiveSlider').length > 0) {
                $('.regionalJobs-partners.responsiveSlider').slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    speed: 800,
                    cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    responsive: [{
                      breakpoint: 5000,
                      settings: 'unslick'
                    }, {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }, {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });
            }
        }else {
            if($('.responsiveSlider.slick-slider').length > 0) {
                $('.responsiveSlider').slick('unslick');
            }
        }
    }

    // Fonction qui permet d'afficher / cacher les documents d'une section
    function showHideDocuments() {
        if($(this).parent().hasClass('open')) {
            $(this).parent().removeClass('open');
            $(this).next().slideUp(600);
        }else {
            $(this).parent().addClass('open');
            $(this).next().slideDown(600);
        }
    }

    // Fonction qui permet de gérer l'affichage des textes trops longs dans les cards
    function adaptCardWithReadMore() {
        if($('.cardgroup-elem').length != 0) {
            $('.cardgroup-elem').each(function() {
                if($(this).children('.cardgroup-content').children('.readmorecontent').length != 0) {
                    var neweHeight = $(this).height() - ($(this).children('.cardgroup-img').height() + 55);
                    $(this).children('.cardgroup-content').css('max-height', neweHeight + 'px');
                }
                $(this).children('.cardgroup-content').children('.btn-readmore').click(function() {
                    $(this).hide();
                    $(this).parent().children('.readmorecontent').slideDown(300);
                    $(this).parent().mCustomScrollbar();
                });
            });
        }
    }

    // Script permettant de gérer la page de Login MyTransdev
    function scriptForMyTransdevLogin() {
        if($('.mytransdevpage-login').length != 0 ) {
            $('.user-login-form input[name="name"]').attr('placeholder', 'Votre adresse email');
            $('.user-login-form input[name="name"]').attr('data-error', 'Veuillez renseigner votre email ou nom d\'utilisateur');
            $('.user-login-form input[name="pass"]').attr('placeholder', 'Votre mot de passe');
            $('.user-login-form input[name="pass"]').attr('data-error', 'Veuillez renseigner votre mot de passe');

            $('.user-login-form .js-form-submit').click(function(e) {
                e.preventDefault();
                var nbChampVide = 0;
                $('.user-login-form .error-msg').remove();
                $('.globalerror').remove();
                $('.fieldError').removeClass('fieldError');
                $('.valid-msg').removeClass('valid');
                $('.valid').removeClass('valid');

                $('.user-login-form input').each(function() {
                    if($(this).val() == "" || $(this).val() == " " || $(this).val() == null) { // On vérifie que le champ n'est pas vide
                        var textError = $(this).attr('data-error');
                        nbChampVide++;
                        $(this).addClass('fieldError');
                        $(this).parent().append( "<div class='error-msg'>" + textError + "</div>" );
                        $('.error-msg').fadeIn();
                    }else {
                        $(this).addClass('valid');

                    }
                });

                if(nbChampVide == 0) {
                    $.ajax({
                        type: "POST",
                        url: $('.user-login-form').attr('action'),
                        data: $('.user-login-form').serialize(), // serializes the form's elements.
                        beforeSend: function() {
                            $('.user-login-form .js-form-submit').fadeOut(200, function() {
                                $('.user-login-form .js-form-submit').attr('value', 'Chargement...');
                                $('.user-login-form .js-form-submit').fadeIn(200);
                            });
                        },
                        success: function(data) {
                            var datas = data.toString();
                            if(datas.indexOf('Nom d\'utilisateur ou mot de passe non reconnu.') != -1) {
                                $('.js-form-type-password').after('<div class="globalerror"><p>Nom d\'utilisateur ou mot de passe non reconnu. Veuillez réessayer en vérifiant votre saisie.</p><p class="lostPassword">En cas d\'oubli de votre mot de passe, merci de vous rapprocher du votre service d\'exploitation.</p>');
                                $('.user-login-form input[name="pass"]').val('');
                                $('.fieldError').removeClass('fieldError');
                                $('.valid-msg').removeClass('valid');
                                $('.valid').removeClass('valid');
                                $('.user-login-form .js-form-submit').attr('value', 'Se connecter');
                            }else {
                                $('.globalerror').remove();
                                $('.user-login-form').addClass('is-valid');
                                document.location.href = 'https://' + window.location.hostname + '/my-transdev/';
                            }
                        },
                        error: function(xhr, ajaxOptions, thrownError) {
                            if(xhr.status == 0) {
                                document.location.href = 'https://' + window.location.hostname + '/my-transdev/';
                                $('.user-login-form').addClass('is-valid');
                            }else {
                                $('.js-form-type-password').after('<div class="globalerror">Désolé, une erreur est survenue. Veuillez réessayer.');
                                $('.user-login-form .js-form-submit').attr('value', 'Se connecter');
                            }
                        }
                    });
                }
            });

            $('.user-login-form input').focusout(function() {
                if($(this).val() == "" || $(this).val() == " " || $(this).val() == null) { // On vérifie que le champ n'est pas vide
                    var textError = $(this).attr('data-error');
                    $(this).addClass('fieldError');
                    if($(this).parent().children('.error-msg').length == 0) {
                        $(this).parent().append("<div class='error-msg'>" + textError + "</div>");
                    }else {
                        $('.error-msg').fadeOut();
                    }
                    $('.error-msg').fadeIn();
                }else {
                    $(this).removeClass('fieldError');
                    $(this).addClass('valid');
                    $(this).parent().children('.error-msg').remove();
                }
            });
        }
    }

    // Fonction qui permet d'afficher les modales après envoi d'un formulaire
    function showModalAfterSendForm() {
        if($('.showMessageConfirmModal').length != 0) {
            $('html, body').css('overflow', 'hidden');
            $('.modal').fadeIn(400, function() { $('.modal-content').addClass('open'); });
            $('.modal-close').click(function() {
                $('.modal-content').removeClass('open');
                setTimeout(function() { $('.modal').fadeOut(500); $('body').removeClass('blured'); $('html, body').removeAttr('style'); }, 300);
            });
        }
    }

    // Fonction qui permet de supprimer le bouton "Un devis" sur les pages "Lignes régulières"
    function verifIfIsRegularLinesPage() {
        if($('.submenu .is-active').html() == 'Lignes régulières') $('.btn-sticky').remove();
    }

    // Fonction qui permet d'afficher un formulaire de réservation
    function showReservationForm() {
       if($('.blockForm').length != 0)
           $('.blockForm').slideDown(300);
           $('html, body').animate({ scrollTop: $('.blockForm').offset().top - 100  }, 600);
    }

    // Fonction qui permet d'afficher les dates disponibles pour un formulaire de réservation
    function addSelectListDates() {
        if($('.datesForEvent').length != 0 && $('.blockForm input[name="date"]').length != 0) {
            $('#edit-quote-request-details .fieldset-wrapper').prepend('<div class="form-row js-form-item form-item js-form-type-select form-item-gender js-form-item-gender">\n' +
                '<label for="edit-gender" class="js-form-required form-required">Sélectionnez une date</label>\n' +
                '<select data-webform-required-error="Veuillez choisir une date" id="edit-date" name="date" class="form-select required" required="required" aria-required="true"><option value="" selected="selected">- Choisir une date -</option></select>\n' +
                '</div>');

            $('.datesForEvent ul li').each(function() {
                $('#edit-date').append('<option value="' + $(this).html() + '">' + $(this).html() + '</option>');
            });

            $('#edit-date').change(function() {
                $('input[name="date"]').val($('#edit-date').val());
                if($('#edit-date').val() != '') {
                    $('#edit-date').addClass('valid');
                    $('#edit-date').removeClass('error');
                    $('#edit-date').parent().children('.error-msg').fadeOut(300);
                }else {
                    $('#edit-date').removeClass('valid');
                    $('#edit-date').addClass('error');
                    $('#edit-date').parent().children('.error-msg').fadeIn(300);
                }
            });
        }
    }

    // AXEPTIO
    /* le consentement contextuel axeptio - youtube */
    (_axcb = window._axcb || []).push(function(sdk) {
      sdk.on('cookies:complete', function(choices){
        document
          .querySelectorAll('[data-hide-on-vendor-consent]')
          .forEach(el => {
            const vendor = el.getAttribute('data-hide-on-vendor-consent');

            el.style.display = choices[vendor] ? 'none' : 'inherit';
          });

        /* custom GM */
        document
          .querySelectorAll('[data-show-on-vendor-consent]')
          .forEach(el => {
            const vendor = el.getAttribute('data-show-on-vendor-consent');

            el.style.display = choices[vendor] ? 'inherit' : 'none';
          });

        /* iframe */
        document
          .querySelectorAll('[data-requires-vendor-consent]')
          .forEach(el => {
            const vendor = el.getAttribute('data-requires-vendor-consent');
            if (choices[vendor]) {
              console.log("tutu");

              el.setAttribute('src', el.getAttribute('data-src'));
              el.style.display = 'initial';

              var btn = el.parentElement.querySelectorAll('.axeptio-youtube')[0];
              if (typeof btn !== 'undefined') {
                btn.style.display = 'none';
              }
            } else {
              var btn = el.parentElement.querySelectorAll('.axeptio-youtube')[0];

              if (typeof btn === 'undefined') {
                el.style.display = 'none';
                var btn = document.createElement("button");
                btn.setAttribute('onClick',"window.axeptioSDK.requestConsent('"+vendor+"')");
                btn.classList.add('axeptio-youtube');
                btn.innerText += "Pour voir cette vidéo je dois accepter les cookies "+vendor+"";
                el.parentElement.appendChild(btn);
              }
            }
          });
      });
    });

