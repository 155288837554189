  var currentUrl = window.location.href;

  /* site VAD */
  if(currentUrl.indexOf('https://www.transdev-vad.com') !== -1) {
    console.log('site VAD');
  var matomo_tracking = "nzYsI1VE";
}

  /* site Region */
  if(currentUrl.indexOf('https://www.transdev-bplcvl.com') !== -1) {
    console.log('site Regions');
    var matomo_tracking = "Dm0uSCFY";
}

  /* site Bretagne */
  if(currentUrl.indexOf('https://www.transdev-bretagne.com') !== -1) {
    console.log('site Bretagne');
    var matomo_tracking = "c2M82b8j";
}

  /* site Bretagne ou departements : Cotes-d-Armor - Finistere - Ille-et-Vilaine - Morbihan  */
  if(currentUrl.indexOf('transdev-bretagne.com') !== -1) {
    console.log('site Bretagne ou departements');
    var matomo_tracking_parent = "57xOc6tU";
}


  /* site Cotes-d-Armor */
  if(currentUrl.indexOf('cotesdarmor.transdev-bretagne.com') !== -1) {
    console.log('site Cote d-armor');
    var matomo_tracking = "UFwuxSYi";
}

  /* site Finistere */
  if(currentUrl.indexOf('finistere.transdev-bretagne.com') !== -1) {
    console.log('site Finistere');
    var matomo_tracking = "7FlCcZdp";
}

  /* site Ille-et-Vilaine */
  if(currentUrl.indexOf('illeetvilaine.transdev-bretagne.com') !== -1) {
    console.log('site Ile et vilaine');
    var matomo_tracking = "bEW3Rh5I";
}

  /* site Morbihan */
  if(currentUrl.indexOf('morbihan.transdev-bretagne.com') !== -1) {
    console.log('site Morbihan');
    var matomo_tracking = "ZKTS1A1R";
}


  /* site PDL */
  if(currentUrl.indexOf('https://www.transdev-paysdelaloire.com') !== -1) {
    console.log('site PDL');
    var matomo_tracking = "bEmkbbAO";
}

  /* site PDL ou departements : Loire atlantique - Maine et loire - Mayenne - Sarthe */
  if(currentUrl.indexOf('transdev-paysdelaloire.com') !== -1) {
    console.log('site PDL ou departements');
    var matomo_tracking_parent = "YIrwIhCm";
}


  /* site Loire atlantique vendee */
  if(currentUrl.indexOf('loireatlantique.transdev-paysdelaloire.com') !== -1) {
    console.log('site Loire atlantique vendee');
    var matomo_tracking = "pxGuElX2";
}

  /* site Maine et loire */
  if(currentUrl.indexOf('maineetloire.transdev-paysdelaloire.com') !== -1) {
    console.log('site Maine et loire');
    var matomo_tracking = "eTZujcWC";
}

  /* site Mayenne */
  if(currentUrl.indexOf('mayenne.transdev-paysdelaloire.com') !== -1) {
    console.log('site Mayenne');
    var matomo_tracking = "WmgKATBx";
}

  /* site Sarthe */
  if(currentUrl.indexOf('sarthe.transdev-paysdelaloire.com') !== -1) {
    console.log('site Sarthe');
    var matomo_tracking = "CAofcmmj";
}


  /* site Centre val de loire */
  if(currentUrl.indexOf('https://www.transdev-centrevaldeloire.com') !== -1) {
    console.log('site centre val de loire');
    var matomo_tracking = "moBySSS2";
}

  /* site Centre val de loire ou departement : centre val de loire - eure et loir - indre et loire - loire et cher - loiret */
  if(currentUrl.indexOf('transdev-centrevaldeloire.com') !== -1) {
    console.log('site centre val de loire ou departements');
    var matomo_tracking_parent = "6nbVJ4LC";
}

  /* site Eure et loir */
  if(currentUrl.indexOf('eureetloir.transdev-centrevaldeloire.com') !== -1) {
    console.log('site eure et loir');
    var matomo_tracking = "gIj2CwPk";
}

  /* site Indre et loire */
  if(currentUrl.indexOf('indreetloire.transdev-centrevaldeloire.com') !== -1) {
    console.log('site indre et loire');
  var matomo_tracking = "3esB5WMR";
}

  /* site Loir et cher */
  if(currentUrl.indexOf('loiretcher.transdev-centrevaldeloire.com') !== -1) {
    console.log('site loire et cher');
  var matomo_tracking = "64zKpunm";
}

  /* site Loiret */
  if(currentUrl.indexOf('loiret.transdev-centrevaldeloire.com') !== -1) {
    console.log('site loiret');
  var matomo_tracking = "exL7hsDi";
}

/* Script Matomo */
var _mtm = window._mtm = window._mtm || [];
_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
g.async=true;
g.src='https://cdn.matomo.cloud/transdev-bplcvl.matomo.cloud/container_'+ matomo_tracking +'.js';
s.parentNode.insertBefore(g,s);
console.log(g.src);

if(typeof matomo_tracking_parent === 'undefined') {
  console.log('pas de parent');
} else {
  var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
  g.async = true;
  g.src = 'https://cdn.matomo.cloud/transdev-bplcvl.matomo.cloud/container_' + matomo_tracking_parent + '.js';
  s.parentNode.insertBefore(g, s);
  console.log(g.src);
}
